import { createApp } from 'vue'
import App from './App.vue'
//import router from './router.js'
import './assets/tailwind.css'
//import { guest } from './plugins/axios.js'
import { guest } from './plugins/axios'

//createApp(App).use(guest).mount('#app')

createApp(App).provide('$guest', guest).mount('#app')


/*const app = createApp({
    data: () => ({
        guest: true  
      }),
})*/
