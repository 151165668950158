<template>
  <!--<div id="app" class="container mx-auto p-4">-->
    <!--<Navbar/>-->
    <!--<div class="w-full bg-gray-100 shadow-lg p-4 rounded-lg">-->

  <div id="app" class="container mx-auto flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <div
        class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800"
      >
      <div class="flex flex-col overflow-y-auto md:flex-row">
        <div class="h-64 md:h-auto md:w-1/2">
          <img
            aria-hidden="true"
            class="object-cover w-full h-full dark:hidden"
            src="./assets/img/sat-logo.png"
            alt="SAT Logo"
          />
          <img
            aria-hidden="true"
            class="hidden object-cover w-full h-full dark:block"
            src="./assets/img/sat-logo.png"
            alt="SAT Logo"
          />
        </div>
        <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
          <div class="w-full">
            <h1
              class="mb-4 md:mb-8 text-xl text-center font-semibold text-gray-700 dark:text-gray-200"
            >
            Skills Assessment Tool
            </h1>

            <section class="mb-6">
              <form @submit.prevent="login()" action="/login" method="post">
                <input class="hidden" type="text" id="grant_type" name="grant_type" value="password">
                <label class="block text-sm font-semibold">
                  <span class="text-gray-700 dark:text-gray-400">Nom d'usuari</span>
                  <input
                    class="block w-full mt-1 py-2 px-3 border rounded text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    placeholder="username" name="username" id="username" type="text" 
                    v-model="username" 
                  />
                </label>
                <label class="block mt-4 text-sm font-semibold">
                  <span class="text-gray-700 dark:text-gray-400">Contrasenya</span>
                  <input
                    class="block w-full mt-1 border rounded py-2 px-3 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    placeholder="***************" name="password" id="password" type="password" 
                    v-model="password" 

                  />
                </label>
                <alert v-if="msg" :msg="msg" :classAlert="classAlert"></alert>
                <router-link to="/signin">
                  <span></span>
                </router-link>
                <button 
                    class="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                    type="submit"
                >
                    Entrar
                </button>              
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>  
  </div>
</template>


<script>
//import Navbar from './components/Navbar.vue'
import Alert from './components/Alert.vue'
//import { guest } from '.plugins/axios'

export default {
  name: 'App',
  data() {
    return {
      username: "",
      password: "",
      msg: null,
      classAlert: null,
    }
  },
  components: {
    Alert,
    //guest,
  //  Navbar
  },
  methods: {
    login() {
      //console.log("Intentando entrar");
      const form = new FormData()
      form.append('username', this.username)
      form.append('password', this.password)

      this.$guest.post('/login', form)
        .then(() => {
          //console.log(res.data);
          this.msg = "Has entrado!"
          this.classAlert = "success"
        })
        .catch(err => {
          this.msg = err.response.data.message.console.error()
          this.classAlert = "danger"
        })
    }
  }
}
</script>

<style>

</style>