<template>
    <div class="block w-full mt-1 p-4 text-sm text-red-400 border-l-4 border-red-600 dark:text-gray-300 dark:bg-gray-700 focus:border-red-400 focus:outline-none focus:shadow-outline-red" role="alert">
        {{msg}}
    </div>
</template>

<script>
export default {
    props: ["msg"]
}
</script>

<style>

</style>