//import Vue from 'vue'
import axios from 'axios'

const guest = axios.create({
    //baseURL: 'http://192.168.101.110/',
    baseURL: 'https://sat2.danielpastor.pro/teachers/challenges/238',
    headers: {
        "Content Type": "application/x-www-form-urlencoded",
        "X-Requested-With": "XMLHttpRequest",

    },
})

//Vue.prototype.$guest = guest
//const aplicacion = Vue.createApp({})
//app.config.globalProperties.$guest = guest;
//Vue.provide('$guest', guest)

export { guest }